import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_solutions.js";
import { Link } from "gatsby";
import { Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p className="leading-relaxed text-lg">
  Get feedback about your activities directly from your guests. Get access to
  statistics about your content and the user activity.
    </p>
    <br />
    <br />
    <h4>{`Statistics dashboard`}</h4>
    <p>{`With access to the Trailguide dashboard you get an overview over the content
statistics, reviews and condition reports.`}</p>
    <br />
    <p>{`Get a quick overview over the most popular activities, and browse important
feedback from your guests, such as reviews and condition reports.`}</p>
    <br />
    <p>{`You can easily customize the dashboard and the data you see. Get an overview
over all your content or limit it to certain areas or activites.`}</p>
    <br />
    <h4>{`User feedback`}</h4>
    <p>{`Get to know what your guests think about your activities and content. You can
browse user reviews in your dashboard or see it visually on your trail map.`}</p>
    <Image src="solutions/reviewmap.png" className="my-12 w-2/3 mx-auto" mdxType="Image" />
    <br />
    <br />
    <h4>{`Condition reports`}</h4>
    <p>{`Engage your guests to give feedback to get valuable information about the
conditions of your trails.`}</p>
    <br />
    <p>{`Be able to quickly react to incidents like fallen trees, broken bridges, or
other hazards. Close the trail and send your trail building team to fix it.`}</p>
    <br />
    <p>{`See which trails tend to be in bad conditions and under which circumstances.
Identify possibilities to improve your product and the guests experience.`}</p>
    <Image src="solutions/conditionreportsphone.png" className="my-12 w-3/4 mx-auto" mdxType="Image" />
    <br />
    <br />
    <h4>{`Heatmap`}</h4>
    <p>{`With access to Trailguide Professional you can inspect the traffic patterns
of your guests. Where are popular areas? Where do they often make a detour
and the signage could be improved?`}</p>
    <Image src="solutions/heatmap.png" className="mt-12 w-2/3 mx-auto" mdxType="Image" />
    <div className="text-center text-gray-700 text-sm italic mt-2 mb-12">
  The heatmap anonymously visualizes user data.
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      